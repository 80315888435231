<template>
  <div class="live-page">
    <MenuBar :overlay="true" />
    
    <div class="live-wrap">
      <div class="title">
        <h3>
          <b>LIVE</b> GAME
        </h3>
      </div>
      <div class="content">
        <div class="main-wrap">´
          <Chessboard :orientation="orientation" :overlayTag="endType" :fen="type == 'UNSET' ? defaultFen : fen" />
          <div :class="{'player-wrap': 1, reversed: orientation == 'b'}">
            <PlayerBox :name="blackName" :turn="turn == 'b'" :overlayTag="blackWinTag" :time="blackClock" :hasTime="hasTime" :isRunning="blackClockRunning" :size="124" />
            <PlayerBox :name="whiteName" :turn="turn == 'w'" :overlayTag="whiteWinTag" :time="whiteClock" :hasTime="hasTime" :isRunning="whiteClockRunning" :size="124" />
          </div>
          <OverlayMessage v-if="showOverlay" :animation="animation">
            <p>{{overlayText}}</p>
          </OverlayMessage>
        </div>
      </div>
    </div>

     <DownloadBlock />
    <Footer />
  </div>
</template>

<script>
import MenuBar from '../components/MenuBar.vue'
import Chessboard from '../components/Chessboard.vue'
import PlayerBox from '../components/PlayerBox.vue'
import DownloadBlock from '../components/DownloadBlock.vue'
import Footer from '../components/Footer.vue'
import OverlayMessage from '../components/OverlayMessage.vue'
import livestreamService from '../services/livestreamService'

export default {
  name: 'Live',
  data: () => {
    return {
      defaultFen: "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1",
      error: null,
      type: null,
      orientation: null,
      whiteName: "Player 1",
      blackName: "Player 2",
      whiteClock: 0,
      blackClock: 0,
      whiteClockRunning: false,
      blackClockRunning: false,
      fen: "",
      turn: "w",
      endType: null,
      winnerColor: null,
    }
  },
  computed: {
    blackWinTag() {
      if (this.winnerColor == "b") return "VICTORY";
      else if (this.winnerColor == "w") return "DEFEAT";
      else if (this.winnerColor == "d") return "DRAW";
      return null;
    },
    whiteWinTag() {
      if (this.winnerColor == "b") return "DEFEAT";
      else if (this.winnerColor == "w") return "VICTORY";
      else if (this.winnerColor == "d") return "DRAW";
      return null;
    },
    showOverlay() {
      return this.error != null || this.type == null || this.type == "CLOSED" || this.type == "UNSET";
    },
    hasTime() {
      return !(this.whiteClock == 0 && this.blackClock == 0);
    },
    animation() {
      if (this.error != null) return '/animations/error.json';
      else if (this.type == null) return '/animations/loading.json';
      else if (this.type == "CLOSED") return '/animations/closed.json';
      else if (this.type == "UNSET") return '/animations/waiting.json';
      return null;
    },
    overlayText() {
      if (this.error != null) return this.error;
      else if (this.type == null) return "Loading ...";
      else if (this.type == "CLOSED") return 'Livestream has ended Thank you for watching!';
      else if (this.type == "UNSET") return 'Waiting for Streamer ...';
      return null;
    }
  },
  async created() {
    livestreamService.subscribe(this.$route.params.gameId, this.gameUpdate);
  },
  methods: {
    gameUpdate({ error, data }) {
      console.log(error, data);
      if (error) {
        this.error = error;
        return;
      }
      this.type = data.type;
      this.fen = data.fen;
      this.turn = data.turn;
      this.orientation = data.orientation;
      this.whiteName = data.whiteName || "White Player";
      this.blackName = data.blackName || "Black Player";
      this.whiteClock = data.whiteClock || 0;
      this.blackClock = data.blackClock || 0;
      this.whiteClockRunning = data.whiteClockRunning;
      this.blackClockRunning = data.blackClockRunning;
      this.endType = data.endType;
      this.winnerColor = data.winnerColor;
    }
  },
  components: {
    MenuBar,
    Footer,
    PlayerBox,
    DownloadBlock,
    Chessboard,
    OverlayMessage,
  }
}
</script>

<style scoped>
.live-page {
  padding: 0;
  margin: 0;
}

.live-wrap {
  background: #252732;
  height: auto;
  min-height: 100vh;
  padding: 120px 56px 40px 56px;
  margin-bottom: 80px;
  box-sizing: border-box;
}

.main-wrap {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 40px 0;
}

h3 {
  font-size: 32px;
  text-align: right;
  color: #ffffff;
  margin: 0;
}

h3 b {
  color: rgb(183, 93, 105);
}

.player-wrap {
  display: flex;
  flex-direction: column;
}

.player-wrap.reversed {
  display: flex;
  flex-direction: column-reverse;
}

.player-wrap > * {
  flex-grow: 1;
  margin: 16px;
}

.animation {
  width: 100%;
  height: 300px;
}

@media screen and (max-width: 950px) {
  .main-wrap {
    flex-direction: column;
    align-items: center;
  }

  .live-wrap {
    padding: 120px 0 40px 0;
  }

  h3 {
    text-align: center;
  }

  .player-wrap {
    flex-direction: row;
  }
  
  .player-wrap.reversed {
    flex-direction: row-reverse;
  }
}

@media screen and (max-width: 500px) {
  .player-wrap > * {
    flex-grow: 1;
    margin: 16px 4px;
  }
}
</style>
