<template>
  <div>
    <NotificationBar>
      
    </NotificationBar>
    <MenuBar />
    <div class="page">
      <Header />
      <!-- <Brands :brands="brands" /> -->
      <ScrollIndicator />

      <FeatureItemsBlockDark>
        <template slot="subtitle">
          Features
        </template>
        <template slot="title">
          Yep, it does it all but stays simple to use ...
        </template>
        <template slot="items">
          <FeatureItemsBlockDarkItem v-for="feature in features" :key="feature.key" :icon="feature.icon" :title="feature.title" :description="feature.description" />
        </template>
      </FeatureItemsBlockDark>

      <div class="divider"></div>
      
      <ItemsBlock id="compatibility">
        <template slot="subtitle">
          Cross-Board Compatibility
        </template>
        <template slot="title">
          Our focus is on providing the best possible experience <b>across all boards and brands</b>.
        </template>
        <template slot="items">
          <ItemsBlockItem v-for="brand in brands" :key="brand.key">
            <div class="brand-image" :class="{small: brand.small}" :style="{backgroundImage: `url(${brand.img})`}"></div>
          </ItemsBlockItem>
        </template>
      </ItemsBlock>

      <div class="divider small"></div>

      <SupportTable />

      <div class="divider"></div>

      <StepsBlock id="puzzlebuilder">
        <template slot="subtitle">
          Chess Puzzles
        </template>
        <template slot="title">
          Stay sharp with <b>Chess Puzzles</b>
        </template>
        <template slot="content">
          On WhitePawn you can not only solve puzzles but you can design and share them.<br><br>
          All you need to get started is our PuzzleBuilder.<br><br>
          <a class="raised" href="/puzzle/builder" target="_blank">Open PuzzleBuilder</a>
        </template>
        <template slot="steps">
          <StepItemBlock icon="img/icons/create.png" iconBackgroundColor="#252732">
            <template slot="title">
              <b>Design</b> Puzzles
            </template>
            <template slot="content">
              No matter if you want to train checkmate patterns or openings,
              WhitePawn provides a puzzle builder that makes it super easy to do.
            </template>
          </StepItemBlock>
          <StepItemBlock icon="img/icons/share.png" iconBackgroundColor="#252732">
            <template slot="title">
              <b>Share</b> Puzzles
            </template>
            <template slot="content">
              Every Puzzle can be easily exported as File. You can then share your puzzles with your friends on Facebook and Twitter.
            </template>
          </StepItemBlock>
          <StepItemBlock icon="img/icons/play.png" iconBackgroundColor="#252732">
            <template slot="title">
              <b>Solve</b> Puzzles
            </template>
            <template slot="content">
              No matter what board you're playing on, we've got you covered! 
              If you are on the go you can continue seamlessly on your smartphone!
            </template>
          </StepItemBlock>
        </template>
      </StepsBlock>

      <div class="divider"></div>

      <!-- <BigNumBlock :number="rating">
        <template slot="title">
          We strongly believe in the power of <b>your feedback</b>.<br>
          <ToggleButton a="GooglePlay" b="AppStore" :active="selectedRating" @change="onRatingChange" />
        </template>
      </BigNumBlock> -->

      <div class="divider"></div>

      <DownloadBlock />
      <Footer />
    </div>
  </div>
</template>

<script>
import MenuBar from '../components/MenuBar.vue'
import Header from '../components/Header.vue'
// import Block from '../components/Block.vue'
// import BigNumBlock from '../components/BigNumBlock.vue'
import FeatureItemsBlockDark from '../components/FeatureItemsBlockDark.vue'
import FeatureItemsBlockDarkItem from '../components/FeatureItemsBlockDarkItem.vue'
import ItemsBlock from '../components/ItemsBlock.vue'
// import ToggleButton from '../components/ToggleButton.vue'
import ItemsBlockItem from '../components/ItemsBlockItem.vue'
import DownloadBlock from '../components/DownloadBlock.vue'
// import Brands from '../components/Brands.vue'
// import Features from '../components/Features.vue'
import ScrollIndicator from '../components/ScrollIndicator.vue'
import SupportTable from '../components/SupportTable.vue'
import StepsBlock from '../components/StepsBlock.vue'
import StepItemBlock from '../components/StepItemBlock.vue'
import Footer from '../components/Footer.vue'
import NotificationBar from '../components/NotificationBar.vue'

export default {
  name: 'Home',
  components: {
    MenuBar,
    Header,
    // Block,
    // BigNumBlock,
    FeatureItemsBlockDark,
    FeatureItemsBlockDarkItem,
    ItemsBlock,
    ItemsBlockItem,
    // Brands,
    // Features,
    ScrollIndicator,
    SupportTable,
    DownloadBlock,
    StepsBlock,
    StepItemBlock,
    // ToggleButton,
    Footer,
    NotificationBar,
  },
  computed: {
    rating() {
      if (this.selectedRating == 0) return 4.7;
      if (this.selectedRating == 1) return 5.0; 
      return 0;
    }
  },
  data() {
    return {
      active: false,
      selectedRating: 0,
      brands: [
        { key: "chessnut", img: "/img/brands/chessnut.png" },
        { key: "dgt", img: "/img/brands/dgt.jpg", small: 1 },
        { key: "millennium", img: "/img/brands/millennium.png" },
        { key: "certabo", img: "/img/brands/certabo.png" },
      ],
      features: [
        { key: "online", icon: ['fa', 'globe-europe'], title:"Play Online", description: "Challenge the world of chess via lichess" },
        { key: "engines", icon: ['fa', 'bolt'], title:"Train with Engines", description: "Play and study position against engines", },
        { key: "puzzles", icon: ['fa', 'puzzle-piece'], title:"Solve Puzzles", description: "Train your ability of pattern recognition" },
        { key: "analysis", icon: ['fa', 'search'], title:"Analyse your Games", description: "Learn more about your recently played games" },
      ]
    };
  },
  methods: {
    onRatingChange(index) {
      this.selectedRating = index;
    },
  }
}
</script>

<style>
.brand-image {
  display: block;
  height: 100%;
  width: 100%;
  margin: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.8;
}

.brand-image.small {
  margin: 40px;
}

.divider {
  height: 64px;
}

.divider.small {
  height: 34px;
}

a.raised {
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;
  margin: 0 10px;
  padding: 18px 72px;
  border-radius: 42px;
  background: rgb(183, 93, 105);
  color: white;
  font-size: 24px;
}

a.raised:hover {
  background: rgb(215, 120, 133);
}

a.raised:active {
  background: rgb(124, 54, 64);
}
</style>