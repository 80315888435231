<template>
  <section class="steps-block-item">
    <div class="logo" :style="{ backgroundColor: iconBackgroundColor, backgroundImage: `url(${icon})` }"></div>
    <div class="content">
      <h3><slot name="title"></slot></h3>
      <p><slot name="content"></slot></p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'StepItemBlock',
  props: {
    icon: String,
    iconBackgroundColor: String,
  }
}
</script>

<style scoped>
.steps-block-item {
  position: relative;
  justify-content: center;
  align-items: flex-start;
  width: 95%;
  box-shadow: 2px 4px 12px -2px rgba(46, 46, 46, 0.05);
  border: 1px solid rgba(240, 240, 240, 0.6);
  border-radius: 26px;
  flex-shrink: 0;
  margin-bottom: 24px;
  padding: 24px 34px;
  display: flex;
  box-sizing: border-box;
}

.content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.logo {
  width: 104px;
  height: 104px;
  margin-right: 24px;
  border-radius: 50%;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center center;
  flex-shrink: 0;
}

h3 {
  margin: 0;
}

p {
  font-size: 20px;
}

@media screen and (max-width: 680px) {
  .steps-block-item {
    width: 100%;
    margin-left: 0;
    flex-direction: column;
  }

  .logo {
    margin-bottom: 24px;
  }
}
</style>
