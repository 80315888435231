import Home from './pages/Home'
import Faq from './pages/Faq'
import Live from './pages/Live'
import PuzzleBuilder from './pages/PuzzleBuilder'

const routes = [
    { path: '/', component: Home, name: "Home" },
    { path: '/support/faq', component: Faq, name: "Faq" },
    { path: '/live/:gameId', component: Live, name: "Live" },
    { path: '/puzzle/builder', component: PuzzleBuilder, name: "PuzzleBuilder" },
    {
        path: '/help/puzzle-builder',
        beforeEnter() {
            window.location.replace("https://youtu.be/t8gP2tCIVko")
        }
    },
];

export default routes;