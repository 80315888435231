<template>
  <div class="overlay-message">
    <div class="message">
      <div ref="animation"></div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import lottie from 'lottie-web';
export default {
  name: 'OverlayMessage',
  props: {
    animation: String
  },
  mounted() {
    this.updateAnimation();
  },
  watch: {
    animation() {
      this.updateAnimation();
    }
  },
  methods: {
    updateAnimation() {
      this.$refs.animation.innerHTML = "";
      lottie.loadAnimation({
        container: this.$refs.animation,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: this.animation
      });
    }
  }
}
</script>

<style scoped>
.overlay-message {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message {
  width: 300px;
  background-color: rgb(196, 196, 196);
  box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  text-align: center;
  font-size: 24px;
  font-style: italic;
}
</style>
