<template>
  <div>
    <MenuBar />
    <div class="page">
      <Block id="faq" :center="true">
        <template slot="subtitle">
          Frequently Asked Questions 
        </template>
        <template slot="title">
          You got a <b>Question</b> about<br>
          the WhitePawn App
        </template>
        <template slot="content">
          <FAQAccordion :items="faqItems" :selectedQuestion="selectedQuestion" @select="onSelect" />
        </template>
      </Block>
      <DownloadBlock />
      <Footer />
    </div>
  </div>
</template>

<script>
import MenuBar from '../components/MenuBar.vue'
import Block from '../components/Block.vue'
import DownloadBlock from '../components/DownloadBlock.vue'
import FAQAccordion from '../components/FAQAccordion.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'Faq',
  components: {
    MenuBar,
    Block,
    FAQAccordion,
    DownloadBlock,
    Footer
  },
  created() {
    if (location.hash) {
      this.selectedQuestion = location.hash.substring(1);
    }
  },
  data() {
    return {
      selectedQuestion: null,
      faqItems: [
        {
          key: "btclassicconnect",
          question: "Can't connect to Bluetooth-Classic Board",
          subquestions: [
            {
              key: "-android",
              question: "Android Device",
              answer: `
              ### First of all:
              1. Make sure you have the latest version of the <a href="https://play.google.com/store/apps/details?id=im.khad.white_pawn">WhitePawn</a> app installed on your Android device.
              
              ### Board does not showup:
              1. Make sure your Board is paired in your device bluetooth settings.

              ### Can't establish connection:
              1. Check if Bluetooth is enabled on your device.
              2. Make sure the device is in range of the board (around 10m).
              3. Make sure the board is powered on.
              `
            },
            { 
              key: "-ios",
              question: "iOS Device",
              answer: `
              Sadly, this is not possible on iOS. We would love to support bluetooth classic boards on iOS, but this is a technical limitation of the current iOS implementation.
              `
            },
          ]
        },
        {
          key: "btleconnect",
          question: "Can't connect to Bluetooth-LE Board",
          subquestions: [
            {
              key: "-android",
              question: "Android Device",
              answer: `
              ### First of all:
              1. Make sure you have the latest version of the <a href="https://play.google.com/store/apps/details?id=im.khad.white_pawn">WhitePawn</a> app installed on your Android device.
              
              ### Board does not showup:
              1. Make sure bluetooth is enabled on your device.
              2. Try to turn on <a href="https://www.youtube.com/shorts/FUfuyXxWNgY">Display unknown devices</a>.

              ### Can't establish connection:
              1. Make sure the device is in range of the board (around 10m).
              2. Make sure the board is powered on.
              3. If you have unknown devices turned on, check if you have choosen the right driver.
              `
            },
            { 
              key: "-ios",
              question: "iOS Device",
              answer: `
              ### First of all:
              1. Make sure you have the latest version of the <a href="https://apps.apple.com/app/id1559535513#?platform=iphone">WhitePawn</a> app installed on your iOS device.
              
              ### Board does not showup:
              1. Make sure bluetooth is enabled on your device.
              2. Try to turn on <a href="https://www.youtube.com/shorts/FUfuyXxWNgY">Display unknown devices</a>.

              ### Can't establish connection:
              1. Make sure the device is in range of the board (around 10m).
              2. Make sure the board is powered on.
              3. If you have unknown devices turned on, check if you have choosen the right driver.
              `
            },
          ]
        },
        {
          key: "usbconnect",
          question: "Can't connect to USB Board",
          subquestions: [
            {
              key: "-android",
              question: "Android Device",
              answer: `
              ### First of all:
              1. Make sure you have the latest version of the <a href="https://play.google.com/store/apps/details?id=im.khad.white_pawn">WhitePawn</a> app installed on your Android device.
              
              ### Board does not showup:
              1. Make sure you are using an OTG-Cable.
              2. Some devices change the name of the usb devices, try to turn on <a href="https://www.youtube.com/shorts/FUfuyXxWNgY">"Display unknown devices"</a>.

              ### Can't establish connection:
              1. Make sure the board is powered on.
              2. If you have unknown devices turned on, check if you have choosen the right driver.
              `
            },
            { 
              key: "-ios",
              question: "iOS Device",
              answer: `
              Sadly, this is not possible on iOS. We would love to support usb boards on iOS, but this is a technical limitation of the current iOS implementation.
              `
            },
          ]
        },
        {
          key: "piecerecognition",
          question: "Pieces are not recognized",
          subquestions: [
            { 
              key: "-certabo",
              question: "Certabo Board",
              answer: `
              Certabo Boards need a initial piece mapping, which can be done in the Certabo Board settings. See how it is done: <a href="https://www.youtube.com/shorts/jK6sX9tLomA">Setup Certabo Board</a>.
              `
            },
          ]
        },
        {
          key: "uciengines",
          question: "Use external UCI-Engines",
          subquestions: [
            {
              key: "-android",
              question: "Android Device",
              answer: `
              ### How to add UCI-Engines:
              Go to Settings > Engines, and add the UCI-Engines you want to use. The Engine need to be available on your device.

              ### How to analyse with the engine:
              To choose a external engine during analysis, longpress the analysis button and select the engine you want to use.
              `
            },
            { 
              key: "-ios",
              question: "iOS Device",
              answer: `
              Sadly, this is not possible on iOS. We would love to support external engines on iOS, but this is a technical limitation of the current iOS implementation.
              `
            },
          ]
        },
        {
          key: "move_announcement",
          question: "Move Announcement",
          subquestions: [
            {
              key: "-not_working_android",
              question: "Not working on Android",
              answer: `
              ### First of all:
              1. Make sure you have the latest version of the <a href="https://play.google.com/store/apps/details?id=im.khad.white_pawn">WhitePawn</a> app installed on your Android device.
              2. Check if the move announcement is enabled in the settings. To do so, start a game and tap the 3-dots-Icon in the upper right corner. You will see a "Person-Talking"-Icon, make sure its not crossed.

              ### Speech Services by Google
              Make sure you have <a href="https://play.google.com/store/apps/details?id=com.google.android.tts">Speech Services by Google</a> installed and it's up to date. Usually this is installed by default but on some devices it's not.
              `
            },
            { 
              key: "-speed",
              question: "Is talking to fast",
              answer: `
              You can set the speed of the speech in the settings. To do so open the Menu on the Startpage and click on "Settings". You should see a Menu-Entry named "Move Announcement". Then you can set the speech speed.
              `
            },
          ]
        },
      ]
    };
  },
  methods: {
    onSelect(key) {
      this.selectedQuestion = key;
      history.replaceState(null, null, `#${key}`);
    }
  }
}
</script>

<style scoped>
#faq {
  margin-top: 50px;
}
</style>