<template>
    <a href="#features" class="scrollindicator" v-smooth-scroll>
        <p>Scroll Down</p>
        <div class="mouse-icon">
            <span class="mouse-wheel"></span>
        </div>
    </a>
</template>

<script>
export default {
  name: 'ScrollIndicator',
  props: {}
}
</script>

<style scoped>
    .scrollindicator{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100px;
        margin: 0 auto;
        margin-bottom: 100px;
        cursor: pointer;
        text-decoration: none;
    }

    .mouse-icon{
        width: 25px;
        height: 45px;
        border: 2px solid rgba(156, 157, 160, 0.6);
        border-radius: 15px;
        position: relative;
        text-align: center;
    }

    .mouse-wheel{
        height: 6px;
        margin: 2px auto 0;
        display: block;
        width: 3px;
        background-color: #686a6d;
        border-radius: 50%;
        -webkit-animation: 1.6s ease infinite wheel-up-down;
        -moz-animation: 1.6s ease infinite wheel-up-down;
        animation: 1.6s ease infinite wheel-up-down;
    }

    p {
        font-size: 12px;
        font-weight: bold;
        color: rgba(156, 157, 160, 0.6);
    }

    @-webkit-keyframes wheel-up-down {
        0% {
            margin-top: 2px;
            opacity: 0;
        }
        30% {
            opacity: 1;
        }
        100% {
            margin-top: 20px;
            opacity: 0;
        }
    }
    
    @-moz-keyframes wheel-up-down {
        0% {
            margin-top: 2px;
            opacity: 0;
        }
        30% {
            opacity: 1;
        }
        100% {
            margin-top: 20px;
            opacity: 0;
        }
    }
    
    @keyframes wheel-up-down {
        0% {
            margin-top: 2px;
            opacity: 0;
        }
        30% {
            opacity: 1;
        }
        100% {
            margin-top: 20px;
            opacity: 0;
        }
    }
</style>
