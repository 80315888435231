const io = require("socket.io-client");

const livestreamService = {
    url: process.env.VUE_APP_LIVESTREAM_ENDPOINT,
    connected: false,
    socket: null,

    connect() {
        this.socket = io(this.url, {
            path: "/ws/player",
            transports: ["websocket"],
            query: {
                "clientType": "WEBSITE_LIVESTREAM"
            }
        });
        this.socket.on("connect", () => this.updateConnection(true));
        this.socket.on("disconnect", () => this.updateConnection(false));
    },

    updateConnection(state) {
        console.log("Connection udpate", state);
        this.connected = state;
    },

    close() {
        this.socket.close();
    },

    subscribe(id, handler) {
        this.socket.on("subscribe/livestream/" + id, handler);
        this.socket.emit("subscribe/livestream", { id });
    },

}  

export default livestreamService;