<template>
  <section class="feature-items-block-dark-item">
    <div class="icon">
      <font-awesome-icon :icon="icon" />
    </div>
    <div class="content">
      <div class="headline">{{title}}</div>
      <p>{{description}}</p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeatureItemsBlockDarkItem',
  props: {
    icon: String,
    title: String,
    description: String,
  }
}
</script>

<style scoped>
.feature-items-block-dark-item {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 240px;
  box-shadow: 2px 4px 12px -2px rgba(46, 46, 46, 0.05);
  border-radius: 26px;
  flex-shrink: 0;
  margin-bottom: 24px;
  background: #363843;
  font-size: 18px;
}

.icon {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 64px;
}

.content {
  color: rgb(120, 120, 120);
  padding: 0 24px 24px 24px;
}

.content .headline {
  color: rgb(211, 211, 211);
}

.content p {
  margin: 0;
}

@media screen and (max-width: 680px) {
  .feature-items-block-dark-item {
    width: 46vw;
    height: 46vw;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .content {
    color: rgb(120, 120, 120);
    padding: 14px;
    text-align: center;
  }
  
  .content p {
    font-size: 14px;
  }

  .icon {
    font-size: 14vw;
  }
}
</style>
