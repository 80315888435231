<template>
  <section class="propertiesbox">
    <div class="chessboard">
      <div class="properties-wrap">
        <h3>Properties</h3>
        <div v-if="lecture">
          <TextField title="Title" v-model="lecture.title" />
          <TextField title="Description" :multiline="true" :rows="3" v-model="lecture.description" />
          <div class="startpos">
            <input id='startpos_picker' type='file' accept="image/*" @change="startPosImageChange" hidden/>
            <TextField @posFocusIn="$emit('posFocusIn')" @posFocusOut="$emit('posFocusOut')" title="Start Position" v-model="lecture.startPosition" />
            <a @click="toggleOrientation" :class="{ orientation: 1, [lecture.orientation]: 1 }"><font-awesome-icon :icon="['fa', 'chess-pawn']" /></a>
            <a @click="openStartPosImagePicker" class="upload-image"><font-awesome-icon :icon="['fa', 'file-upload']" /></a>
          </div>
        </div>
        <br>
      </div>
      <slot name="chessboard"></slot>
    </div>
    <div v-if="lecture" :class="{'moves-wrap': 1, disabled: positionMode}">
      <ul class="variation">
        <li v-for="(_, index) in lecture.variations" :key="index" :class="{active: index == selectedVariationIndex}" @click="$emit('selectVariation', index)">
          {{index + 1}}
        </li>
        <li class="add" @click="$emit('addNewVariation')">
          <font-awesome-icon :icon="['fa', 'plus']" />
        </li>
      </ul>
      <h3>Moves</h3>
      <div class="moves-list">
        <div v-for="(item, index) in selectedVariation" @click="$emit('toggleMoveType', { item, index })" :class="{'move-item': 1, request: item.type == 'request'}" :key="index">
          {{item.move}}
        </div>
        <span class="move-info">White-Moves are requested - Click to toggle</span>
      </div>
      <div @click="$emit('removeLastMove')" class="remove-btn">
        REMOVE LAST
      </div>
      <div @click="selectedVariationIndex == 0 ? () => {} : $emit('deleteVariation', selectedVariationIndex)" :class="{'delete-variation-btn': 1, disabled: selectedVariationIndex == 0}">
        <font-awesome-icon :icon="['fa', 'times']" />
      </div>
    </div>
  </section>
</template>

<script>
import fenfinderService from '../../services/fenfinderService'
import TextField from '../TextField.vue'
import Chess from 'chess.js'

export default {
  name: 'PropertiesBox',
  props: {
    lecture: Object,
    positionMode: Boolean,
    selectedVariationIndex: Number,
  },
  computed: {
    selectedVariation() {
      return this.lecture.variations[this.selectedVariationIndex];
    }
  },
  methods: {
    toggleOrientation() {
      this.lecture.orientation = this.lecture.orientation == "white" ? "black" : "white";
    },
    openStartPosImagePicker() {
      window.document.getElementById('startpos_picker').click();
    },
    async startPosImageChange(e) {
      await fenfinderService.setup();
      const reader = new FileReader();

      reader.addEventListener("load", (re) => {
        var img = new Image();
        img.addEventListener("load", () => {
          const {
            resultCanvasElement,
          } = fenfinderService.processLoadedImage(img);
          const chessboard = fenfinderService.runPrediction(resultCanvasElement);
          console.log(chessboard.fen);
          const chess = new Chess(chessboard.fen + " w - - 0 1");
          this.$emit("predictedFen", chess.fen());
        });
        img.src = re.target.result;
      }, false);

      reader.readAsDataURL(e.target.files[0]);
    }
  },
  components: {
    TextField
  }
}
</script>

<style scoped>
h3 {
  margin: 0;
  color: rgba(255, 255, 255, 0.6);
}
.chessboard {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.propertiesbox {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  padding: 20px 0 0 0;
  border-radius: 12px;
}

.properties-wrap {
  flex-grow: 1;
  margin-right: 24px;
}

.moves-wrap {
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  flex-grow: 1;
  margin: 20px 10px 10px 10px;
  border-radius: 12px;
  padding: 10px 0;
  transition: ease-in 0.2s;
}

.moves-wrap h3 {
  margin-left: 14px;
}

.moves-wrap .variation {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 20px;
  top: -24px;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
}

.moves-wrap .variation li {
  display: block;
  list-style: none;
  width: 40px;
  font-size: 10px;
  background: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.4);
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2px;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid transparent;
}

.moves-wrap .variation li.active {
  background: rgba(255, 255, 255, 0.1);
  color: white;
}

.moves-wrap .variation li.add {
  color: rgba(183, 93, 105, 1);
  background: transparent;
  border: 1px solid rgba(183, 93, 105, 1);
  font-size: 8px;
  width: 26px;
  height: 14px;
}

.moves-wrap .variation li.add:hover {
  color: rgb(201, 117, 128);
  border: 1px solid rgb(201, 117, 128);
}

.moves-wrap.disabled {
  opacity: 0.4;
  filter: saturate(0) blur(1px);
}

.moves-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0 14px;
}

.move-item {
  padding: 2px 4px;
  margin: 6px 4px;
  min-width: 32px;
  border-radius: 18px;
  background-color: rgba(183, 93, 105, 0.7);
  color: white;
  font-size: 11px;
  letter-spacing: 1px;
  font-weight: normal;
  text-align: center;
  cursor: pointer;
  border: 4px solid rgba(27, 27, 27, 0.2);
}

.move-item.request {
  border: 4px solid rgb(255, 255, 255);
  background-color: rgba(183, 93, 105, 1);
  font-weight: bold;
}

.startpos {
  position: relative;
  padding-right: 84px;
}

.startpos a {
  position: absolute;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  color: black;
  cursor: pointer;
}

.startpos a.orientation {
  right: 0;
  bottom: 0;
}

.startpos a.orientation.white {
  color: white;
  background: rgba(255, 255, 255, 0.1);
}

.startpos a.upload-image {
  background: transparent;
  color: rgba(255, 255, 255, 0.4);
  right: 38px;
  bottom: 0;
}

.remove-btn {
  position: absolute;
  top: 9px;
  right: 36px;
  width: 80px;
  color: rgb(99, 99, 99);
  padding: 6px 16px 6px 16px;
  background: rgba(117, 117, 117, 0.2);
  border-radius: 28px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

.remove-btn:hover {
  background: rgba(117, 117, 117, 0.3);
}

.remove-btn:active {
  background: rgba(0, 0, 0, 0.1);
}

.delete-variation-btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 8px;
  right: 4px;
  width: 28px;
  height: 28px;
  color: rgba(117, 117, 117, 0.4);
  border-radius: 50%;
}

.delete-variation-btn.disabled {
  color: rgba(0, 0, 0, 0.2);
}

.delete-variation-btn:hover {
  background: rgba(117, 117, 117, 0.1);
  cursor: pointer;
}

.delete-variation-btn.disabled:hover {
  background: transparent;
  cursor: default;
}

.move-info {
  margin: 6px;
  border-radius: 24px;
  font-size: 12px;
  text-align: center;
  color: rgb(255, 248, 147);
  font-style: italic;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
