<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #252732;
}

.page {
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: white;
  z-index: 2;
}

.menu {
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  box-sizing: border-box;
}

div.rounded-modal {
  box-sizing: border-box;
  border-radius: 26px;
  border: 4px solid rgb(218, 218, 218);
  overflow: hidden;
}

@media screen and (max-width: 680px) {
  .page {
    padding: 0 4px;
  }
}
</style>
