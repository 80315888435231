<template>
  <div :class="{ notification: 1 }">
    🚧 <b>Roadmap</b> and <b>Changelog</b> are now available on <a href="https://whitepawn.productlane.com/roadmap">Productlane</a>
  </div>
</template>

<script>
export default {
  name: 'NotificationBar',
  props: {
    small: Boolean,
    dark: Boolean,
  },
}
</script>

<style scoped>  
  .notification {
    width: 100vw;
    padding: 7px 0;
    background-color: #f5f5f5;
    text-align: center;
    position: relative;
  }

  .notification a {
    color: rgb(183, 93, 105);
    text-decoration: none;
    font-weight: bold;
  }
</style>
