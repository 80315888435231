<template>
  <div class="overlay-menu-item">
    <a v-smooth-scroll>{{title}}</a>
    <div class="content-wrap">
      <div class="content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OverlayMenuItem',
  props: {
    title: String,
  }
}
</script>

<style scoped>
.overlay-menu-item {
  position: relative;
}

.overlay-menu-item a {
  color: rgb(97, 97, 97);
  cursor: pointer;
}

.overlay-menu-item:hover a {
  color: rgb(30, 30, 30);
}

.overlay-menu-item .content-wrap {
  display: none;
  position: absolute;
  box-sizing: border-box;
  left: -20px;
  bottom: -240px;
  height: 240px;
  width: 500px;
  padding-top: 20px;
  z-index: 2;
}

.overlay-menu-item .content {
  position: relative;
  box-sizing: border-box;
  padding: 20px;
  background-color: white;
  border-radius: 24px;
  width: 500px;
  box-shadow: 2px 4px 12px -2px rgb(26 24 24 / 24%);
}

.overlay-menu-item .content::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: calc(20% - 40px);
    box-sizing: border-box;
    
    border: 9px solid black;
    border-color: transparent transparent #ffffff #ffffff;
    
    transform-origin: 0 0;
    transform: rotate(135deg);
    
    box-shadow: -3px 3px 3px 0 rgb(26 24 24 / 7%);
}

.overlay-menu-item:hover .content-wrap {
  display: block;
}
</style>
