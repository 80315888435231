<template>
  <router-link :to="url">
    <div class="logo" :style="{ backgroundColor: iconBackgroundColor, backgroundImage: `url(${icon})` }"></div>
    <div class="text">
      <div class="title">{{title}}</div>
      <div class="subtitle">{{subtitle}}</div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'OverlayMenuSubItem',
  props: {
    title: String,
    subtitle: String,
    url: String,
    icon: String,
    iconBackgroundColor: String,
  }
}
</script>

<style scoped>
a {
  display: flex;
  padding: 12px;
  border-radius: 12px;
}
a:hover {
  background-color: rgb(230, 230, 230);
}
.logo {
  width: 54px;
  height: 54px;
  margin-right: 14px;
  border-radius: 12px;
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center center;
  flex-shrink: 0;
}
.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.subtitle {
  font-size: 14px;
  color: rgb(97, 97, 97);
}
</style>
