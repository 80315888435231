<template>
  <div class="puzzlebuilder-page">    
    <div class="puzzlebuilder-wrap">
      <div class="title">
        <div class="brand">
          <div class="logo"></div>
          <h3>
            <b>PUZZLE</b> BUILDER
          </h3>
        </div>
        <div class="options">
          <a @click="newCollection">New</a>
          <input id='open_collection_picker' type='file' accept=".wppc" @change="collectionOpen" hidden/>
          <a @click="open">Open</a>
          <a @click="download">Download</a>
          <a @click="jsonImport">Import from JSON</a>
        </div>
      </div>
      <div class="content">
        <div class="lecture-wrap">
          
        </div>
        <div class="main-wrap">
          <div class="overview-wrap">
            <CollectionOverview
              :collection="collection"
              :selected="selectedLecture ? selectedLecture.title : null"
              @selectLecture="onSelectLecture"
              @addChapter="onAddChapter"
              @addLecture="onAddLecture"
              @imageSelect="onImageSelect"
              @deleteChapter="onDeleteChapter"
              @deleteLecture="onDeleteLecture"
            />
          </div>
          <div class="properties-wrap">
            <PropertiesBox
              :lecture="selectedLecture"
              :positionMode="positionMode"
              :selectedVariationIndex="selectedVariationIndex"
              @posFocusIn="setPositionMode(true)"
              @predictedFen="onPredictedFen"
              @toggleMoveType="onToggleMoveType"
              @removeLastMove="onRemoveLastMove"
              @addNewVariation="onAddNewVariation"
              @selectVariation="onSelectVariation"
              @deleteVariation="onDeleteVariation"
            >
              <template slot="chessboard">
                <Chessboard
                  :orientation="orientation"
                  :fen="startPos"
                  :mode="positionMode ? 2 : 1"
                  @newFen="onStartPosUpdate"
                  @newMove="onNewMove"
                  @done="setPositionMode(false)"
                />
              </template>
            </PropertiesBox>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-abs">
      <Footer :small="true" :dark="true" />
    </div>
  </div>
</template>

<script>
import Chess from 'chess.js'
import YAML from 'yaml'
import Chessboard from '../components/Chessboard.vue'
import CollectionOverview from '../components/puzzlebuilder/CollectionOverview.vue'
import PropertiesBox from '../components/puzzlebuilder/PropertiesBox.vue'

import Footer from '../components/Footer.vue'

import sampleCollection from '../data/sample-collection.json'

function downloadFile(filename, text) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export default {
  name: 'PuzzleBuilder',
  data: () => {
    return {
      defaultFen: "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1",
      collection: sampleCollection,
      selectedLecture: null,
      selectedVariationIndex: 0,
      positionMode: false,
    }
  },
  computed: {
    chapter() {
      if (!this.collection) return [];
      return this.collection.chapter;
    },
    orientation(){
      return this.selectedLecture ? this.selectedLecture.orientation[0] : null;
    },
    startPos(){
      let lectureFen = this.selectedLecture ? this.selectedLecture.startPosition : null;
      if (this.positionMode) {
        return lectureFen;
      }
      let moves = this.selectedLecture ? this.selectedVariation : [];
      let chess = new Chess(lectureFen ? lectureFen : this.defaultFen);
      for (const move of moves) {
        chess.move(move.move);
      }
      return chess.fen();
    },
    selectedVariation() {
      return this.selectedLecture.variations[this.selectedVariationIndex];
    }
  },
  methods: {
    open() {
      window.document.getElementById('open_collection_picker').click();
    },
    jsonImport() {
      const json = prompt("JSON String");
      if (!json) return;
      try {
        const parsed = JSON.parse(json);
        this.collection = parsed;
      } catch (error) {
        alert("Failed.");
      }
    },
    collectionOpen(e) {
      let fr = new FileReader();
      fr.addEventListener("load", () => {
        const result = Buffer.from(Buffer.from(fr.result).toString(), "base64");
        const doc = YAML.parseDocument(result.toString('utf-8'));
        console.log(doc.toJSON());
        this.collection = doc.toJSON();
      }, false);
      fr.readAsArrayBuffer(e.target.files[0]);
    },
    download() {
      const doc = new YAML.Document();
      doc.contents = this.collection;
      
      let content = new Buffer.from(doc.toString(), 'binary').toString('base64');
      downloadFile(this.collection.title + ".wppc", content);
    },
    newCollection() {
      this.collection = {
        title: "New Collection",
        cover: null,
        description: "",
        chapter: [],
      };
      this.selectedLecture = null;
    },
    onDeleteChapter(chapter) {
      this.collection.chapter = this.chapter.filter((c) => c != chapter);
    },
    onDeleteLecture({chapter, lecture}) {
      this.collection.chapter = this.chapter.map((c) => {
        if (c != chapter) return c;
        return {
          ...c,
          lectures: c.lectures.filter((l) => l != lecture)
        }
      });
    },
    onAddChapter() {
      let title = "New Chapter ";
      let index = 1;
      while (this.chapter.some(x => x.title == title + index)) index++;
      this.chapter.push({
        title: title + index,
        cover: null,
        description: "",
        lectures: []
      });
    },
    onAddLecture(chapter) {
      let title = "New Lecture ";
      let index = 1;
      while (chapter.lectures.some(x => x.title == title + index)) index++;
      chapter.lectures.push({
        title: title + index,
        description: "",
        orientation: "white",
        startPosition: "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1",
        variations: [[]]
      });
    },
    onSelectLecture(lecture) {
      this.selectedLecture = lecture;
    },
    onStartPosUpdate(fen) {
      this.selectedLecture.startPosition = fen;
    },
    onPredictedFen(fen) {
      this.selectedLecture.startPosition = fen;
      this.setPositionMode(true);
    },
    onImageSelect(image) {
      this.collection = {
        ...this.collection,
        cover: image
      };
    },
    setPositionMode(state) {
      this.positionMode = state;
    },
    onNewMove(state) { // todo: update to support multi colors; just make dependet on move before
      this.selectedVariation.push({
        move: state,
        type: this.selectedLecture.orientation == "white"
          ? this.selectedVariation.length % 2 == 1 ? "response" : "request"
          : this.selectedVariation.length % 2 == 0 ? "response" : "request"
      });
    },
    onToggleMoveType({ index: moveIndex }) {
      this.selectedLecture.variations = this.selectedLecture.variations.map((variation, vi) => {
        if (vi == this.selectedVariationIndex) {
          return variation.map((m, mi) => {
            if (mi != moveIndex) return m;
            return {
              ...m,
              type: m.type == "request" ? "response" : "request"
            }
          });
        }
        return variation;
      });
    },
    onRemoveLastMove() {
      this.selectedVariation.pop();
    },
    onAddNewVariation() {
      this.selectedLecture.variations.push([]);
      this.selectedVariationIndex = this.selectedLecture.variations.length - 1;
    },
    onSelectVariation(variationIndex) {
      this.selectedVariationIndex = variationIndex;
    },
    onDeleteVariation(variationIndex) {
      this.selectedVariationIndex--;
      this.selectedLecture.variations.splice(variationIndex, 1);
    }
  },
  components: {
    Footer,
    Chessboard,
    CollectionOverview,
    PropertiesBox,
  }
}
</script>

<style scoped>
.brand {
  display: flex;
  height: 29px;
  color: white;
  align-items: center;
  margin-right: 24px;
}

.content {
  overflow: auto;
  flex-grow: 1;
  margin-bottom: 40px;
  padding: 0 40px;
}

.logo {
  height: 42px;
  width: 42px;
  margin-right: 12px;
  background-image: url(/img/logo_rounded.png);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.puzzlebuilder-page {
  padding: 0;
  margin: 0;
  height: 100vh;
  background: #252732;
}

.puzzlebuilder-wrap {
  background: #252732;
  height: auto;
  box-sizing: border-box;
  height: 100vh;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.main-wrap {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  padding: 50px 0 0 0;
}

.overview-wrap {
  width: 300px;
  flex-shrink: 0;
  height: calc(100vh - 180px);
}

.properties-wrap {
  width: 45%;
  height: calc(100vh - 180px);
  margin: 0 24px;
  flex-grow: 1;
}

.title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 64px;
  flex-shrink: 0;
  margin: 0 40px;
}

h3 {
  font-size: 32px;
  text-align: right;
  color: #ffffff;
  margin: 0;
}

h3 b {
  color: rgb(183, 93, 105);
}

.options {
  display: flex;
  justify-content: flex-start;
}

.options a {
  display: flex;
  align-items: center;
  color: white;
  margin-right: 12px;
  padding: 12px 24px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  font-size: 14px;
  cursor: pointer;
}

.options a:hover {
  background: rgba(0, 0, 0, 0.4);
}

.options a:active {
  background: rgba(0, 0, 0, 0.7);
}

.footer-abs {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
