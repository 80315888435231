<template>
  <div :class="{'overlay-tag': 1, ...(type ? { [type]: 1 } : {})}">
    <div class="tag">
      {{text}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'OverlayTag',
  props: {
    text: String,
    type: String
  },
}
</script>

<style scoped>
.overlay-tag {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}

.overlay-tag.success .tag {
  background-color: #b75d69;
  font-weight: bold;
  font-size: 12px;
}

.overlay-tag.fail .tag {
  background-color: #484848;
  font-weight: bold;
  font-size: 12px;
}

.tag {
  display: inline-block;
  padding: 12px 24px;
  background-color: black;
  color: white;
  border-radius: 32px;
  font-size: 18px;
  letter-spacing: 2px;
}
</style>
