<template>
  <div class="menu">
    <div :class="{'menu-bar': 1, 'overlay': overlay}">
      <div class="fixed-wrap">
        <div class="left-wrap">
          <router-link to="/" class="brand">
            <div class="logowrap">
              <div class="logo"></div>
            </div>
            <h1>WhitePawn</h1>
          </router-link>
          <div class="menu-items">
            <OverlayMenuItem title="Puzzle">
              <OverlayMenuSubItem
                title="Puzzle-Builder"
                subtitle="Build your own puzzle"
                icon="/img/icons/create.png"
                iconBackgroundColor="#252732"
                url="/puzzle/builder"
              />
            </OverlayMenuItem>
            <OverlayMenuItem title="Support">
              <!-- <OverlayMenuSubItem
                title="Roadmap"
                subtitle="Planned features and updates"
                icon="/img/icons/faq.png"
                iconBackgroundColor="#252732"
                url="https://whitepawn.productlane.com/roadmap"
              />
              <OverlayMenuSubItem
                title="Changelog"
                subtitle="Latest updates and changes"
                icon="/img/icons/faq.png"
                iconBackgroundColor="#252732"
                url="https://whitepawn.productlane.com/changelog"
              /> -->
              <OverlayMenuSubItem
                title="FAQ"
                subtitle="Frequently asked questions"
                icon="/img/icons/faq.png"
                iconBackgroundColor="#252732"
                url="/support/faq"
              />
            </OverlayMenuItem>
          </div>
        </div>

        <div class="social">
          <a target="_blank" href="https://www.instagram.com/whitepawn.app">
            <font-awesome-icon :icon="['fab', 'instagram']" />
          </a>
          <a target="_blank" href="https://www.youtube.com/channel/UCnSvEEATZa4Yb6bdQiqqDaw">
            <font-awesome-icon :icon="['fab', 'youtube']" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OverlayMenuItem from '@/components/OverlayMenuItem.vue'
import OverlayMenuSubItem from '@/components/OverlayMenuSubItem.vue'

export default {
  name: 'MenuBar',
  props: {
    overlay: Boolean
  },
  components: {
    OverlayMenuItem,
    OverlayMenuSubItem,
  }
}
</script>

<style scoped>
.menu-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  margin-top: 20px;
  position: relative;
}

.menu-items {
  margin-top: 4px;
  display: flex;
}

.menu-items > * {
  padding: 0 12px;
  font-size: 18px;
  font-weight: bold;
}

.fixed-wrap {
  width: 100%;
  padding: 0 30px;
  max-width: 1400px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.brand {
  display: flex;
  align-items: center;
  padding: 3px 14px;
  border-radius: 12px;
  cursor: pointer;
}

.left-wrap {
  display: flex;
  align-items:center;
}

.brand h1 {
  font-size: 28px;
  margin-left: 6px;
  color: #252732
}

.logowrap {
  border-radius: 12px;
  margin: 0 10px 0 0;
  background: #252732;
}

.logo {
  height: 32px;
  width: 32px;
  margin: 4px;
  background-image: url(/img/icon.svg);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

h1 {
  font-size: 18px;
}

ul {
  display: flex;
  margin: 0;
  padding: 0;
}

li {
  display: inline-block;
  list-style: none;
  padding: 2px 10px;
  margin-right: 10px;
  font-weight: bold;
  font-size: 18px;
}

a {
  text-decoration: none;
  color: #7c7d83;
}

.menu-bar.overlay {
  position: absolute;
  top: 20px;
  left: 35px;
  right: 35px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 50px;
  height: 20px;
  padding: 25px 15px;
}

.menu-bar.overlay .logowrap {
  border-radius: 50%;
  background: black;
}

.menu-bar.overlay .brand:hover {
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.03);
}

.social {
  display: flex;
  justify-content: center;
}

.social a {
  color: rgb(197, 116, 126);
  font-size: 24px;
  padding: 12px;
}

.social a:hover {
  color: rgb(197, 116, 126);
}

@media screen and (max-width: 900px) {
  .brand h1 {
    display: none;
  }
}

@media screen and (max-width: 680px) {
  .brand {
    justify-content: center;
  }

  ul {
    display: none;
  }

  .menu-items {
    display: none;
  }
}
</style>
