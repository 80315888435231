<template>
  <div class="header">
    <div class="description">
      <div>
        <h2><b>Powerup</b> your Chessboard</h2>
        <p>
          <b>💌</b> Export + Share Games<br>
          <b>🔎</b> Analyse with Engines<br>
          <b>🌐</b> Connect to Lichess<br>
          <b>🤝</b> No hidden fees, No Ads!<br>
        </p>
      </div>
      <div class="buttons">
        <a class="raised" href="#download" v-smooth-scroll>Get the App</a>
        <div class="subtext">100% Free</div>
      </div>
    </div>
    <div class="eyecatcher">
      <div v-if="videoShown" class="video">
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/adXAbbL0TF4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <a @click="hideDemo" class="close">
          <font-awesome-icon icon="times" />
        </a>
      </div>
      <a v-if="!videoShown" @click="showDemo" class="demo-button">
        <font-awesome-icon icon="play" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      videoShown: false
    }
  },
  methods: {
    showDemo() {
      this.videoShown = true;
    },
    hideDemo() {
      this.videoShown = false;
    }
  }
}
</script>

<style scoped>
h2 {
  font-size: 58px;
  margin: 0;
}

h2 b {
  color: rgb(183, 93, 105);
}

p {
  margin: 10px 10px 0 10px;
  font-size: 24px;
  line-height: 36px;
  color: #494b53;
}

p b {
  margin-right: 12px;
}

.header {
  display: flex;
  height: 600px;
  margin: 36px 0 36px 0;
  border-radius: 32px;
}

.description {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 43%;
  font-size: 16px;
  background: white;
  border-radius: 32px;
  border-top: none;
  padding: 12px;
}

.eyecatcher {
  flex-grow: 1;
  background-color: rgb(183, 93, 105);
  background-image: url(/img/mockup.png), linear-gradient(36deg, rgba(25,25,32,1) 0%, rgba(58,58,111,1) 27%, rgba(183,93,105,1) 100%);
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 32px;
  overflow: hidden;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(183, 93, 105, 0.7);
  color: white;
  z-index: 1;
  padding: 24px 32px;
  border-radius: 0 0 0 32px;
  font-size: 28px;
  cursor: pointer;
}

.close:hover {
  background: rgb(194, 93, 106);
}

a.raised {
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;
  margin: 0 10px;
  padding: 18px 72px;
  border-radius: 42px;
  background: rgb(183, 93, 105);
  color: white;
  font-size: 24px;
}

a.raised:hover {
  background: rgb(215, 120, 133);
}

a.raised:active {
  background: rgb(124, 54, 64);
}

a.demo-button {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  height: 140px;
  width: 140px;
  border-radius: 40px;
  font-size: 26px;
  color: rgb(255, 255, 255);
  background: rgba(183, 93, 105, 0.9);
  font-weight: bold;
  cursor: pointer;
  transition: ease-out 0.1s;
}

a.demo-button:hover {
  background: rgba(183, 93, 105, 1);
  transform: scale(1.05);
}

a.demo-button:active {
  transform: scale(0.9);
}

a.flat:active {
  background: rgb(182, 182, 182);
}

.buttons {
  margin-top: 42px;
}

.buttons .subtext {
  padding-top: 10px;
  text-align: center;
  font-size: 16px;
  color: rgb(183, 93, 105);
  font-weight: bold;
}

@media screen and (max-width: 1150px) {
  .header {
    flex-direction: column-reverse;
    height: 800px;
  }

  .description {
    width: 100%;
    padding: 35px;
  }

  .eyecatcher {
    border-radius: 32px;
  }

  h2 {
    font-size: 32px;
    margin: 0;
  }

  a.raised {
    margin: 0 0 12px 0;
    text-align: center;
  }

  a.flat {
    text-align: center;
  }
}
</style>
