<template>
  <div class="chessboard-wrap">
    <div
      :class="{ 'piece-trash': 1, 'shown': mode == 2 }"
      @drop='onDrop($event, null)'
      @dragover.prevent
      @dragenter.prevent
    >
      <font-awesome-icon :icon="['fa', 'trash']" />
    </div>
    <div
      :class="{ 'save-btn': 1, 'shown': mode == 2 }"
      @click="$emit('done')"
    >
      DONE
    </div>
    <div :class="{ 'piece-pallete': 1, 'shown': mode == 2 }">
      <ChessPiece :enableDragging="mode >= 1" @pieceDrag='startDrag($event, ["p"])' piece="p" :size="38" />
      <ChessPiece :enableDragging="mode >= 1" @pieceDrag='startDrag($event, ["b"])' piece="b" :size="38" />
      <ChessPiece :enableDragging="mode >= 1" @pieceDrag='startDrag($event, ["n"])' piece="n" :size="38" />
      <ChessPiece :enableDragging="mode >= 1" @pieceDrag='startDrag($event, ["r"])' piece="r" :size="38" />
      <ChessPiece :enableDragging="mode >= 1" @pieceDrag='startDrag($event, ["q"])' piece="q" :size="38" />
      <ChessPiece :enableDragging="mode >= 1" @pieceDrag='startDrag($event, ["k"])' piece="k" :size="38" />
      <ChessPiece :enableDragging="mode >= 1" @pieceDrag='startDrag($event, ["P"])' piece="P" :size="38" />
      <ChessPiece :enableDragging="mode >= 1" @pieceDrag='startDrag($event, ["B"])' piece="B" :size="38" />
      <ChessPiece :enableDragging="mode >= 1" @pieceDrag='startDrag($event, ["N"])' piece="N" :size="38" />
      <ChessPiece :enableDragging="mode >= 1" @pieceDrag='startDrag($event, ["R"])' piece="R" :size="38" />
      <ChessPiece :enableDragging="mode >= 1" @pieceDrag='startDrag($event, ["Q"])' piece="Q" :size="38" />
      <ChessPiece :enableDragging="mode >= 1" @pieceDrag='startDrag($event, ["K"])' piece="K" :size="38" />
    </div>
    <div class="chessboard">
      <div v-for="file in files" :key="file" class="file">
        <div
          v-for="rank in ranks" :key="rank" 
          @drop='onDrop($event, [file, rank])'
          @dragover.prevent
          @dragenter.prevent
          :class="{cell: 1, dark: ((files.indexOf(file) + rank + (orientation == 'b' ? 0 : 1)) % 2)}"
        >
          <ChessPiece :enableDragging="mode >= 1" @pieceDrag='startDrag($event, [pieceLookup(file, rank), file, rank])' :piece="pieceLookup(file, rank)" :size="50" />
        </div>
      </div>
      <OverlayTag v-if="overlayTag" :text="overlayTag" />
    </div>
  </div>
</template>

<script>
import Chess from 'chess.js'
import ChessPiece from './ChessPiece.vue'
import OverlayTag from '../components/OverlayTag.vue'
export default {
  name: 'Chessboard',
  data: () => {
    return {
      filesRaw: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'],
      ranksRaw: [1, 2, 3, 4, 5, 6, 7, 8],
      pieces: ['p', 'b', 'n', 'r', 'q', 'k'],
    };
  },
  props: {
    fen: String,
    overlayTag: String,
    orientation: String,
    mode: Number, // 1 move, 2 edit
  },
  computed: {
    files() {
      if (this.orientation == "b") {
        return [...this.filesRaw].reverse();
      }
      return this.filesRaw;
    },
    ranks() {
      if (this.orientation == "b") {
        return [...this.ranksRaw].reverse();
      }
      return this.ranksRaw;
    }
  },
  methods: {
    getPos(arr) {
      let ranks = (this.orientation == "b") ? [...this.ranks] : [...this.ranks].reverse();
      return (arr[0] + ranks[arr[1] - 1]).toLowerCase();
    },
    pieceLookup(file, rank) {
      if (!this.fen) return '';
      file = this.filesRaw.indexOf(file);
      rank = this.ranksRaw.indexOf(rank);
      let fileFen = this.fen.split("/")[rank];
      let fenIndex = 0;
      let fieldIndex = 0;
      while(fenIndex < fileFen.length && fieldIndex < 8) {
        let char = fileFen.charAt(fenIndex);
        if (this.pieces.indexOf(char.toLowerCase()) >= 0) {
          if (fieldIndex == file) {
            return char;
          }
          fieldIndex++;
        } else {
          let skip = parseInt(char);
          if(skip >= 1) {
            fieldIndex += skip;
          }
        }
        fenIndex++;
        continue;
      }
      return '';
    },
    startDrag(evt, info) {
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      let [piece, ...pos] = info;
      evt.dataTransfer.setData('info', JSON.stringify([
         pos.length > 0 ? this.getPos(pos) : null,
         piece
      ]));
    },
    onDropMove(evt, pos) {
      let chess = new Chess(this.fen);
      const [ start ] = JSON.parse(evt.dataTransfer.getData('info'));
      if (start && pos) {
        let valid = chess.move({ from: start.toLowerCase(), to: this.getPos(pos).toLowerCase() });
        console.log({ from: start.toLowerCase(), to: this.getPos(pos).toLowerCase() }, valid, chess.history());
        if (valid) this.$emit("newMove", chess.history()[0]);
      }
    },
    onDropEdit(evt, pos) {
      let chess = new Chess(this.fen);
      const [ start, piece ] = JSON.parse(evt.dataTransfer.getData('info'));

      if (start) {
        chess.remove(start.toLowerCase());
      }
      if (pos) {
        chess.put({ type: piece.toLowerCase(), color: piece.toLowerCase() != piece ? "w" : "b" }, this.getPos(pos));
      }

      this.$emit("newFen", chess.fen());
    },
    onDrop(evt, pos) {
      if (this.mode == 1) {
        this.onDropMove(evt, pos);
      } else if (this.mode == 2) {
        this.onDropEdit(evt, pos);
      }
    }
  },
  components: {
    ChessPiece,
    OverlayTag,
  }
}
</script>

<style scoped>
.chessboard-wrap {
  position: relative;
}

.chessboard {
  position: relative;
  display: flex;
  justify-content: center;
  width: 480px;
  height: 480px;
  color: gray;
  border-radius: 24px;
  border: 4px solid rgba(168, 168, 168, 0.6);
  overflow: hidden;
  box-shadow:0 1px 12px rgba(0, 0, 0, 0.4), 0 0 40px rgba(0, 0, 0, 0.2) inset;
  z-index: 2;
}

.file {
  position: relative;
  width: 12.5%;
  height: 100%;
}

.cell {
  width: 100%;
  height: 12.5%;
  background: rgb(240, 217, 181);
  display: flex;
  justify-content: center;
  align-items: center;
}

.cell.dark {
  background: rgb(181, 136, 99);
}

.piece-pallete {
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  padding: 10px;
  bottom: 10px;
  left: 20px;
  right: 20px;
  border-radius: 12px;
  display: flex;
  justify-content: space-evenly;
  transition: ease-out 0.4s;
  z-index: 1;
}

.piece-trash {
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  padding: 10px;
  top: 10px;
  right: 20px;
  width: 56px;
  border-radius: 12px;
  display: flex;
  justify-content: space-evenly;
  transition: ease-out 0.4s;
  z-index: 1;
}

.save-btn {
  position: absolute;
  background-color: rgb(183, 93, 105);
  color: white;
  font-weight: bold;
  font-size: 12px;
  padding: 10px;
  top: 10px;
  left: 20px;
  width: 56px;
  border-radius: 12px;
  display: flex;
  justify-content: space-evenly;
  transition: ease-out 0.4s;
  cursor: pointer;
  z-index: 1;
}

.save-btn:hover {
  background-color: rgb(206, 117, 129);
}

.save-btn:active {
  background-color: rgb(165, 85, 96);
  transition: background-color ease-out 0s;
}

.piece-pallete.shown {
  bottom: -70px;
}

.piece-trash.shown {
  top: -42px;
}

.save-btn.shown {
  top: -42px;
}

@media screen and (max-height: 900px) {
  .chessboard {
    width: 350px;
    height: 350px;
  }
}

@media screen and (max-width: 1200px) {
  .chessboard {
    width: 350px;
    height: 350px;
  }
}
</style>
