<template>
  <section class="items-block-item">
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: 'ItemsBlockItem',
  props: {}
}
</script>

<style scoped>
.items-block-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 110px;
  box-shadow: 2px 4px 12px -2px rgba(46, 46, 46, 0.05);
  border: 1px solid rgba(240, 240, 240, 0.6);
  border-radius: 26px;
  flex-shrink: 0;
  margin-bottom: 24px;
}
</style>
