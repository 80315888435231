<template>
  <section :class="{'items-block': 1}">
    <div class="content">
      <span class="subtitle"><slot name="subtitle"></slot></span>
      <h3><slot name="title"></slot></h3>
    </div>
    <div class="items">
      <slot name="items"></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ItemsBlock',
  props: {}
}
</script>

<style scoped>
.items-block {
  margin: 0;
  font-size: 26px;
  display: flex;
  align-items: center;
}

.items-block .items {
  position: relative;
  width: 420px;
  margin-top: 42px;
  margin-left: 75px;
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
}

.items-block .items *:first-child {
  margin-left: 10%;
}

.items-block .items *:nth-child(odd) {
  margin-right: 4%;
}

.subtitle {
  display: items-block;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #9c9da0;
  margin: 0;
}

h3 {
  font-size: 52px;
  text-align: left;
  color: #252732;
  margin: 12px 0 0 0;
}

h3 b {
  color: rgb(183, 93, 105);
}

@media screen and (max-width: 1150px) {
  .items-block {
    flex-direction: column;
  }
}

@media screen and (max-width: 680px) {
  h3 {
    font-size: 36px;
  }

  .items-block .items *:first-child {
    margin-left: 0;
  }

  .items-block .items {
    width: 100%;
    box-sizing: border-box;
    margin-left: 0;
    margin-right: 0;
    justify-content: center;
  }

  .content {
    margin: 0 16px;
  }
}
</style>
