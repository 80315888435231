<template>
  <ul class="faq">
    <li v-for="item in items" :key="item.question" @click="triggerSelect(item.key)" :class="{ selected: (selectedQuestion || '').startsWith(item.key) }">
      <span>{{item.question}}</span>
      <div class="body">
        <ul>
          <li
            v-for="subquestion in item.subquestions"
            :key="item.key + subquestion.key"
            class="subquestion"
            :class="{ selected: selectedQuestion == item.key + subquestion.key}"
            @click="$emit('select', item.key + subquestion.key)"
          >
            {{subquestion.question}}
            <div class="answer">
              <p v-html="markdownToHtml(subquestion.answer)"></p>
            </div>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</template>

<script>
import { marked } from 'marked';

export default {
  name: 'FAQAccordion',
  props: {
    items: [],
    selectedQuestion: {
      type: String
    }
  },
  methods: {
    triggerSelect(key) {
      if ((this.selectedQuestion || '').startsWith(key)) return;
      this.$emit('select', key)
    },
    markdownToHtml(markdown) {
     return marked.parse(markdown.split("\n").map(l => l.trim()).join("\n"));
   }
  }
}
</script>

<style scoped>
.faq {
  box-sizing: border-box;
  margin: 40px auto;
  max-width: 900px;
  border-radius: 24px;
  padding: 40px 0;
}

.faq li.subquestion {
  margin: 12px 12px 12px 0;
}

.faq li.subquestion.selected {
  box-shadow: none;
  cursor: default;
}

.faq li.subquestion .answer {
  font-size: 16px;
}

.faq li.subquestion.selected .answer {
  max-height: 1200px;
  transition: max-height ease-in 0.6s;
}

.faq li {
  position: relative;
  padding: 20px 24px;
  list-style: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 24px;
  border-radius: 16px;
  transition: ease-out 0.3s;
  margin-bottom: 12px;
}

.faq li .body, .faq li .answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height ease-out 0.24s;
}

.faq li.selected {
  box-shadow: 2px 4px 12px -2px rgba(26, 24, 24, 0.07);
  cursor: default;
}

.faq li.selected .body {
  max-height: 700px;
  transition: max-height ease-in 0.6s;
}

.faq li::before {
  content: "+";
  padding-right: 12px;
  font-weight: bold;
  color: rgb(183, 93, 105);
}

.faq li.selected::before {
  content: ">";
}

.faq li:hover {
  background-color: rgb(236, 236, 236);
}

.faq li.selected:hover {
  background-color: white;
}

@media screen and (max-width: 600px) {
  .faq li {
    font-size: 20px;
    margin-left: 0;
  }
  .faq ul {
    padding-left: 0;
  }
}
</style>
