<template>
  <div :class="{ playerbox: 1, turn }">
    <div class="name">
      {{name}}
    </div>
    <div class="time" v-if="hasTime && isRunning">
      <vue-countdown :time="time" :interval="100" v-slot="{ hours, minutes, seconds, milliseconds }">
        {{ (hours * 60 + minutes) > 0 ? (hours * 60 + minutes) + "min" : "" }} 
        {{ `${seconds}`.padStart(2, "0") }}.{{ Math.floor(milliseconds / 100) }}s
      </vue-countdown>
    </div>
    <div class="time" v-else-if="hasTime">
        {{ (hours * 60 + minutes) > 0 ? (hours * 60 + minutes) + "min" : "" }} 
        {{ `${seconds}`.padStart(2, "0") }}.{{ Math.floor(milliseconds / 100) }}s
    </div>
    <div class="time" v-else>
      Turn
    </div>
    <OverlayTag v-if="overlayTag" :text="overlayTag" :type="tagType" />
  </div>
</template>

<script>
import OverlayTag from '../components/OverlayTag.vue'
export default {
  name: 'PlayerBox',
  props: {
    name: String,
    turn: Boolean,
    overlayTag: String,
    hasTime: Boolean,
    isRunning: Boolean,
    time: Number,
  },
  computed: {
    hours() {
      return Math.floor(this.time / 1000 / 60 / 60);
    },
    minutes() {
      return Math.floor((this.time - this.hours * 60 * 60 * 1000) / 1000 / 60);
    },
    seconds() {
      return Math.floor((this.time - this.hours * 60 * 60 * 1000 - this.minutes * 1000 * 60) / 1000);
    },
    milliseconds() {
      return Math.floor((this.time - this.hours * 60 * 60 * 1000 - this.minutes * 1000 * 60 - this.seconds * 1000));
    },
    tagType() {
      if (this.overlayTag == "VICTORY") return "success";
      if (this.overlayTag == "DEFEAT") return "fail";
      return "";
    }
  },
  components: {
    OverlayTag
  }
}
</script>

<style scoped>
.playerbox {
  position: relative;
  background: rgb(71, 71, 71);
  width: 240px;
  height: 240px;
  border-radius: 24px;
  border: 4px solid transparent;
  overflow: hidden;
}

.playerbox.turn {
  border: 4px solid #b75d69;
}

.playerbox:hover .name {
  background: rgba(0, 0, 0, 0.6);
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
}

.name {
  position: absolute;
  top: 6px;
  left: 6px; 
  right: 6px; 
  padding: 12px 24px;
  border-radius: 30px;
  color: rgba(255, 255, 255, 0.2);
  font-weight: normal;
  text-align: center;
  transition: ease-out 0.3s;
}

.time {
  box-sizing: border-box;
  position: absolute;
  bottom: 12px;
  width: 80%;
  left: calc(10%);
  padding: 6px 25px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 24px;
  color: white;
  font-weight: bold;
  text-align: center;
}

@media screen and (max-width: 550px) {
  .playerbox {
    width: 180px;
    height: 180px;
  }
}

@media screen and (max-width: 400px) {
  .playerbox {
    width: 140px;
    height: 140px;
  }
}
</style>
