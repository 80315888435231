<template>
  <section :class="{block: 1, center, right, dark}">
    <span class="subtitle"><slot name="subtitle"></slot></span>
    <h3><slot name="title"></slot></h3>
    <div>
      <slot name="content"></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Block',
  props: {
    center: Boolean,
    right: Boolean,
    dark: Boolean
  }
}
</script>

<style scoped>
.block {
  margin: 0 25px 105px 25px;
  font-size: 26px;
}

.block.dark {
  margin: 0 0 105px 0;
  background-color: #252732;
  border-radius: 26px;
  padding: 45px;
}

.block.dark h3{
  color: white;
}

.block.dark h3 b {
  color: rgb(183, 93, 105);;
}

.block.dark .subtitle {
  color: rgba(255, 255, 255, 0.6);
}


.center .subtitle {
  text-align: center;
}

.center h3 {
  text-align: center;
}

.right .subtitle {
  text-align: right;
}

.right h3 {
  text-align: right;
}

.subtitle {
  display: block;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #9c9da0;
  margin: 0;
}

h3 {
  font-size: 52px;
  text-align: left;
  color: #252732;
  margin: 12px 0 0 0;
}

h3 b {
  color: rgb(183, 93, 105);
}

@media screen and (max-width: 600px) {
  .block {
    margin: 0 0 105px 0;
  }

  h3 {
    font-size: 26px;
  }
}
</style>
