import * as tf from '@tensorflow/tfjs'
import Filters from './filters';

const frozen_graph = "/web_model/model.json";

let predictor;

// Contains functions to find centered + aligned chessboards in uploaded images.
function findMax(arr, a, b) {
  // Assumes arr contains positives values.
  var maxVal = -1;
  var maxIdx = 0;
  for (var i = a; i < b; i++) {
    if (arr[i] > maxVal) {
      maxVal = arr[i];
      maxIdx = i;
    }
  }
  return {max: maxVal, idx: maxIdx};
}

// Sum up all the sobelX along rows and sobelY along colummns into 1D vectors.
function squashSobels(pixels) {
  var w = pixels.width;
  var h = pixels.height;
  var d = pixels.data;
  var scoreX = new Int32Array(w);
  var scoreY = new Int32Array(h);
  var buffer = 0; // only use central bit of image
  for (var y=buffer; y<h-buffer; y++) {
    for (var x=buffer; x<w-buffer; x++) {
      var off = (y*w+x)*4;
      scoreX[x] += d[off];
      scoreY[y] += d[off+1]
    }
  }
  return {x:scoreX, y:scoreY}
}

// Global ids used: uploadedImage, resultCanvas, sobelCanvas
function processLoadedImage(img) {
  console.log("Processing image...");
  var uploadedImageElement = document.createElement('canvas');
  var resultCanvasElement = document.createElement('canvas');
  var sobelCanvas = document.createElement('canvas');
  var ctx = sobelCanvas.getContext('2d');

  // Resize the image
  var internalCanvas = document.createElement('canvas'),
      width = 512,
      height = Math.floor((img.height * width) / img.width);
  internalCanvas.width = width;
  internalCanvas.height = height; // purposefully want a square
  internalCanvas.getContext('2d').drawImage(img, 0, 0, width, height);
  uploadedImageElement.width = width;
  uploadedImageElement.height = height;
  uploadedImageElement.getContext('2d').drawImage(img,0,0, width, height);


  // Blur image, then run sobel filters on it.
  // imgData = Filters.getPixels(internalCanvas);
  var d = Filters.filterImage(Filters.gaussianBlur, internalCanvas, 15); // Blur it slightly.
  d = Filters.sobel(d);

  // Visualize sobel image.
  sobelCanvas.width = d.width;
  sobelCanvas.height = d.height;
  sobelCanvas.getContext('2d').putImageData(d, 0, 0);

  // Get squashed X and Y sobels (by summing along columns and rows respectively).
  var squashed = squashSobels(d);
  // Since our image width is forced to 512px, we assume a chessboard is at least half of the image, up to exactly the image
  // This comes out to 32-64 pixels per tile, so we only look for deltas between lines in the range 31-65 pixels.

  // We will non-max supress everything more than 20 pixels away from the strongest lines.

  // Since we also assume that the user has kept the chessboard centered in the image, we can start by looking for the strongest
  // line crossing in the center area, and try and grow out from there.
  var winsize = 30;
  // Find max in center X.
  var ctrX = findMax(squashed.x, Math.floor(width/2)-winsize, Math.floor(width/2)+winsize);
  // Find next max to the right.
  var rightX = findMax(squashed.x, ctrX.idx+31, ctrX.idx+65);

  // Find max in center Y.
  var ctrY = findMax(squashed.y, Math.floor(height/2)-winsize, Math.floor(height/2)+winsize);
  // Find next max to the bottom.
  var botY = findMax(squashed.y, ctrY.idx+31, ctrY.idx+65);

  var deltaX = rightX.idx - ctrX.idx;
  var deltaY = botY.idx - ctrY.idx;

  // Assumes ctrX.idx is the center, there are 4 to the left and 4 to the right.
  var positionsX = Array(9).fill(0).map((e,i)=>(i-4) * deltaX + ctrX.idx);
  var positionsY = Array(9).fill(0).map((e,i)=>(i-4) * deltaY + ctrY.idx);


  // Overlay lines onto sobel image.
  ctx.beginPath();
  // X
  for (var i = 0; i < positionsX.length; i++) {
    ctx.moveTo(positionsX[i], positionsY[0]);
    ctx.lineTo(positionsX[i], positionsY[positionsY.length-1]);
  }
  ctx.lineWidth = 2;
  ctx.strokeStyle = '#ff0000';
  ctx.stroke();
  ctx.closePath()

  // Y
  ctx.beginPath();
  for (var j = 0; j < positionsY.length; j++) {
    ctx.moveTo(positionsX[0],positionsY[j]);
    ctx.lineTo(positionsX[positionsX.length-1],positionsY[j]);
  }
  ctx.lineWidth = 2;
  ctx.strokeStyle = '#00ff00';
  ctx.stroke();

  var bbox = {
    tl: {x: positionsX[0], y: positionsY[0]},
    tr: {x: positionsX[positionsX.length-1], y: positionsY[0]},
    br: {x: positionsX[positionsX.length-1], y: positionsY[positionsY.length-1]},
    bl: {x: positionsX[0], y: positionsY[positionsY.length-1]}
  };

  // Border
  ctx.beginPath();
  ctx.moveTo(bbox.tl.x, bbox.tl.y);
  ctx.lineTo(bbox.tr.x, bbox.tr.y);
  ctx.lineTo(bbox.br.x, bbox.br.y);
  ctx.lineTo(bbox.bl.x, bbox.bl.y);
  ctx.lineTo(bbox.tl.x, bbox.tl.y);
  ctx.lineWidth = 4;
  ctx.strokeStyle = '#ffff00';
  ctx.stroke();

  // Build bounded and aligned grayscale 256x256 px chessboard to result canvas for prediction.
  resultCanvasElement.width = 256;
  resultCanvasElement.height = 256;
  var gray_img = Filters.toCanvas(Filters.filterImage(Filters.grayscale, internalCanvas));
  resultCanvasElement.getContext('2d').drawImage(gray_img,bbox.tl.x,bbox.tl.y, deltaX*8, deltaY*8, 0, 0, 256, 256);

  return {
    uploadedImageElement,
    resultCanvasElement,
    sobelCanvas,
  };
}

// Turns a 256x256x[1,3] pixel image array containing 
// 32x32 chessboard tiles into a 64x1024 array, where 
// each row is one 32x32 tile rolled out.
function getTiles(img_256x256) {
  // TODO: This is a bit hacky, but we can reshape files properly so lets just reshape every
  // file(column) and concat them together.
  var files = []; // 8 columns.
  // Note: Uses first channel, since it assumes images are grayscale.
  for (var i = 0; i < 8; i++) {
    // Entire (32*8)x32 file of 8 tiles, reshaped into an  8x1024 array
    files[i] = img_256x256.slice([0,0+32*i,0],[32*8,32,1]).reshape([8,1024]); 
  }
  return tf.concat(files); // Concatanate all 8 8x1024 arrays into 64x1024 array.
}

function getLabeledPiecesAndFEN(predictions) {
  // Build 2D array with piece prediction label for each tile, matching the input 256x256 image.
  var pieces = [];
  for (var rank = 8 - 1; rank >= 0; rank--) {
    pieces[rank] = [];
    for (var file = 0; file < 8; file++) {
      // Convert integer prediction into labeled FEN notation.
      pieces[rank][file] = '1KQRBNPkqrbnp'[predictions[rank+file*8]]
    }
  }

  // Build FEN notation and HTML links for analysis and visualization.
  // Note: Does not contain castling information, lichess will automatically figure it out.
  var basic_fen = pieces.map(x => x.join('')).join('/')
    .replace(RegExp('11111111', 'g'), '8')
    .replace(RegExp('1111111', 'g'), '7')
    .replace(RegExp('111111', 'g'), '6')
    .replace(RegExp('11111', 'g'), '5')
    .replace(RegExp('1111', 'g'), '4')
    .replace(RegExp('111', 'g'), '3')
    .replace(RegExp('11', 'g'), '2');

  return {piece_array: pieces, fen:basic_fen};
}

// Globals element id's used: resultCanvas, fen
// Global variable used: predictor
function runPrediction(imgCanvas) {
  console.log("Predicting on Input image...");

  // Load pixels from aligned/bounded 256x256 px grayscale image canvas.
  const img_data = tf.browser.fromPixels(imgCanvas).asType('float32');

  // The image is loaded as a 256x256x3 pixel array, even though it's grayscale.
  // We just use the first channel since all should be the same.
  // Then, we need to properly reshape the array so that each 32x32 tile becomes a 1024 long row
  // in a [Nx1024] 2d tf array, where N = 64 for the 64 tiles.
  const tiles = getTiles(img_data);
  // Run model prediction on tiles.
  const outputs = predictor.execute({Input: tiles, KeepProb: tf.scalar(1.0)}); // NOTE - global used here.
  // Get model prediction.
  const raw_predictions = outputs[outputs.length - 1].dataSync();
  // Get labeled piece array and basic FEN prediction.
  const chessboard = getLabeledPiecesAndFEN(raw_predictions);
  return chessboard;
}

const fenfinderService = {
  async setup() {
    if (predictor) return;
    predictor = await tf.loadGraphModel(frozen_graph);
  },
  processLoadedImage,
  runPrediction,
};

export default fenfinderService;