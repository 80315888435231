<template>
  <section class="collectionoverview">
    <h3>Puzzle Collection</h3>
    <div class="properties-wrap">
      <div :class="{cover: 1, picked: coverSrc != null}" @click="openCoverPicker" :style="{backgroundImage: coverSrc}">
        <input id='cover_image_picker' type='file' accept="image/*" @change="imageChange" hidden/>
        <div>Cover Image</div>
        <font-awesome-icon :icon="['fa', 'plus-circle']" />
      </div>
      <TextField title="Title" v-model="collection.title" />
      <TextField title="Description" :multiline="true" :rows="3" v-model="collection.description" />
    </div>
    <h3>Chapter List</h3>
    <div class="chapter-item" v-for="chap in chapter" :key="chap.title">
      <div class="chapter-item-header">
        <span class="title" contenteditable="true" @blur="changeChapterTitle($event, chap)">{{chap.title}}</span> <span class="counter">({{chap.lectures.length}})</span>
        <div class="actions">
          <a @click="$emit('deleteChapter', chap)"><font-awesome-icon :icon="['fa', 'trash']" /></a>
          <a @click="$emit('addLecture', chap)"><font-awesome-icon :icon="['fa', 'plus-circle']" /></a>
        </div>
      </div>
      <div class="lectures">
        <div v-for="lecture in chap.lectures" :key="lecture.title" :class="{'lecture-item': 1, selected: selected == lecture.title }" @click="$emit('selectLecture', lecture)">
          {{lecture.title}}
          <div class="actions">
            <a @click="$emit('deleteLecture', {chapter: chap, lecture})"><font-awesome-icon :icon="['fa', 'times']" /></a>
          </div>
        </div>
      </div>
    </div>
    <div @click="$emit('addChapter')" class="chapter-item-add-new">
      Add new Chapter
    </div>
  </section>
</template>

<script>
import TextField from '../TextField.vue'

export default {
  name: 'CollectionOverview',
  props: {
    collection: Object,
    selected: String
  },
  computed: {
    chapter() {
      if (!this.collection) return [];
      return this.collection.chapter;
    },
    coverSrc() {
      if (!this.collection || !this.collection.cover) return null;
      return `url(${this.collection.cover})`;
    }
  },
  methods: {
    changeChapterTitle(e, chapter) {
      console.log(e.target.innerText);
      chapter.title = e.target.innerText;
    },
    openCoverPicker() {
      window.document.getElementById('cover_image_picker').click();
    },
    imageChange(e) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        this.$emit("imageSelect", reader.result);
      }, false);

      reader.readAsDataURL(e.target.files[0]);
    }
  },
  components: {
    TextField
  }
}
</script>

<style scoped>
h3 {
  margin: 0 0 14px 24px;
  color: rgba(255, 255, 255, 0.6);
}

.properties-wrap {
  padding: 14px;
  margin: 0 10px 30px 10px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.05);
}

.cover {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 120px;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.2);
  margin-bottom: 24px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.cover.picked {
  color: rgba(255, 255, 255, 0);
}

.cover.picked:hover {
  color: rgba(255, 255, 255, 0);
}

.cover:hover {
  color: rgba(255, 255, 255, 0.4);
}

.cover div {
  margin-bottom: 7px;
}

.collectionoverview {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  padding: 20px 0;
  border-radius: 12px;
  overflow: auto;
}

.lecture-item {
  position: relative;
  padding: 6px 14px;
  margin: 14px 20px 14px 10px;
  color: rgb(136, 136, 136);
  border-radius: 28px;
  cursor: pointer;
}

.lecture-item:hover {
  background: rgba(117, 117, 117, 0.2);
}

.lecture-item.selected::before {
  content: "#";
  display: inline-block;
  margin-right: 4px;
  color: rgb(183, 93, 105);
}

.chapter-item {
  margin: 0 20px 14px 0;
}

.chapter-item-header {
  position: relative;
  padding: 6px 48px 6px 16px;
  background: rgb(117, 117, 117);
  border-radius: 0 28px 28px 0;
  cursor: default;
}

.chapter-item-header .counter {
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 2px;
}

.chapter-item-header .title {
  font-weight: bold;
  cursor: text;
}

.chapter-item-add-new {
  color: rgb(99, 99, 99);
  padding: 6px 16px 6px 16px;
  background: rgba(117, 117, 117, 0.2);
  margin: 24px 20px 14px 20px;
  border-radius: 28px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}

.actions {
  position: absolute;
  right: 1px;
  top: 1px;
  display: flex;
  justify-content: flex-end;
}

.actions a {
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.actions a:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgb(200, 200, 200);
  cursor: pointer;
}
</style>
