import { render, staticRenderFns } from "./PlayerBox.vue?vue&type=template&id=60502472&scoped=true&"
import script from "./PlayerBox.vue?vue&type=script&lang=js&"
export * from "./PlayerBox.vue?vue&type=script&lang=js&"
import style0 from "./PlayerBox.vue?vue&type=style&index=0&id=60502472&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60502472",
  null
  
)

export default component.exports