<template>
  <section :class="{'steps-block': 1}">
    <div class="content">
      <span class="subtitle"><slot name="subtitle"></slot></span>
      <h3><slot name="title"></slot></h3>
      <p><slot name="content"></slot></p>
    </div>
    <div class="steps">
      <slot name="steps"></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: 'StepsBlock',
  props: {}
}
</script>

<style scoped>
.steps-block {
  font-size: 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.steps-block .steps {
  position: relative;
  margin-top: 42px;
  margin-left: 7%;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
}

.steps-block .steps *:nth-child(even) {
  margin-left: 5%;
}

.steps-block .steps > *:nth-child(odd) {
  margin-left: 0;
}

.steps-block .steps > * {
  margin-right: 4%;
}

.subtitle {
  display: steps-block;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #9c9da0;
  margin: 0;
}

h3 {
  font-size: 52px;
  text-align: left;
  color: #252732;
  margin: 12px 0 0 0;
}

h3 b {
  color: rgb(183, 93, 105);
}

p {
  line-height: 34px;
}

@media screen and (max-width: 1150px) {
  .steps-block {
    flex-direction: column;
  }
  .steps-block .steps {
    margin-left: 12px;
    margin-right: 12px;
  }
  .steps-block .steps > * {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 680px) {
  h3 {
    font-size: 36px;
  }

  .content {
    margin: 0 25px;
  }
  
  .steps-block .steps {
    margin-left: 12px;
    margin-right: 12px;
  }

  .steps-block .steps * {
    margin-right: 0;
  }
}
</style>
