<template>
  <div class="wrap" :class="{ expanded }" :style="{height: height}">
    <div class="supporttable">
      <div class="title">
        Supported Boards
      </div>
      <section ref="table" class="supporttable-body">
        <div class="header">Board</div>
        <div class="header center">iOS</div>
        <div class="header center">Android</div>

        <div>Chessnut Air (BLE)</div>
        <div class="success center"><font-awesome-icon icon="check" /></div>
        <div class="success center"><font-awesome-icon icon="check" /></div>

        <div>Chessnut Pro</div>
        <div class="success center"><font-awesome-icon icon="check" /></div>
        <div class="success center"><font-awesome-icon icon="check" /></div>

        <div>DGT Pegasus</div>
        <div class="success center"><font-awesome-icon icon="check" /></div>
        <div class="success center"><font-awesome-icon icon="check" /></div>

        <div>DGT Smart Board</div>
        <div class="fail center"><font-awesome-icon icon="times" /></div>
        <div class="success center"><font-awesome-icon icon="check" /></div>

        <div>DGT BT</div>
        <div class="fail center"><font-awesome-icon icon="times" /></div>
        <div class="success center"><font-awesome-icon icon="check" /></div>

        <div>DGT USB (USB-C)</div>
        <div class="fail center"><font-awesome-icon icon="times" /></div>
        <div class="success center"><font-awesome-icon icon="check" /></div>

        <div>DGT USB (Micro-USB)</div>
        <div class="fail center"><font-awesome-icon icon="times" /></div>
        <div class="success center"><font-awesome-icon icon="check" /></div>

        <div>Millennium eONE</div>
        <div class="success center"><font-awesome-icon icon="check" /></div>
        <div class="success center"><font-awesome-icon icon="check" /></div>

        <div>Millennium Supreme Tournament 55</div>
        <div class="success center"><font-awesome-icon icon="check" /></div>
        <div class="success center"><font-awesome-icon icon="check" /></div>

        <div>Millennium Exclusiv</div>
        <div class="success center"><font-awesome-icon icon="check" /></div>
        <div class="success center"><font-awesome-icon icon="check" /></div>

        <div>Millennium Performance</div>
        <div class="success center"><font-awesome-icon icon="check" /></div>
        <div class="success center"><font-awesome-icon icon="check" /></div>

        <div>Certabo Boards (USB)</div>
        <div class="fail center"><font-awesome-icon icon="times" /></div>
        <div class="success center"><font-awesome-icon icon="check" /></div>

        <div>SquareOff Pro</div>
        <div class="success center"><font-awesome-icon icon="check" /></div>
        <div class="success center"><font-awesome-icon icon="check" /></div>

        <div>iChess One</div>
        <div class="success center"><font-awesome-icon icon="check" /></div>
        <div class="success center"><font-awesome-icon icon="check" /></div>
      </section>
    </div>
    <div class="button-wrap">
      <button @click="toggleExpand" class="expand-button">
        {{ expanded ? 'Show less Boards' : 'Show all Boards' }}
        <font-awesome-icon icon="chevron-down" />
      </button>
    </div>
  </div>
</template>

<script>
const collapsedHeight = "250px";

export default {
  name: 'SupportTable-body',
  props: {
    screenSrc: String
  },
  data() {
    return {
      expanded: false,
      height: collapsedHeight,
    };
  },
  methods: {
    toggleExpand() {
      this.expanded = !this.expanded;

      if (this.expanded) {
        this.height = this.$refs.table.clientHeight + 124 + "px";
      } else {
        this.height = collapsedHeight;
      }
    }
  }
}
</script>

<style scoped>
.wrap {
  position: relative;
  margin: 0 auto 0 auto;
  max-width: 950px;
  overflow: hidden;
  position: relative;
  transition: ease-in-out 0.2s;
}

.title {
  text-align: center;
  padding: 14px 0;
  background-color: #252732;
  font-weight: bold;
  color: white;
  font-size: 18px;
}

.button-wrap {
  position: absolute;
  background: linear-gradient(rgb(255, 255, 255, 0.2), #ffffff);
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

button.expand-button {
  width: 235px;
  height: 52px;
  border: none;
  background-color: rgb(244, 244, 244);
  border-radius: 32px;
  font-size: 18px;
  font-weight: bold;
  color: #000;
  cursor: pointer;
  margin: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.wrap button.expand-button svg {
  transition: ease-in-out 0.2s;
}

.wrap.expanded button.expand-button svg {
  transform: rotate(180deg);
}

button.expand-button:hover {
  color: black;
}

.supporttable {
  border-radius: 24px;
  border: 1px solid rgb(240, 240, 240);
  margin-bottom: 84px;
  overflow: hidden;
}

.supporttable-body {
  text-align: left;
  box-sizing: border-box;
  padding: 40px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 70% auto auto;
}

.supporttable-body div {
  border-bottom: 1px solid rgb(228, 228, 228);
  font-size: 18px;
  margin-bottom: 10px;
}

.supporttable-body div.center {
  text-align: center;
}

.supporttable-body div.success {
  color: rgb(68, 185, 103);
  font-size: 22px;
}

.supporttable-body div.fail {
  color: rgb(185, 68, 68);
  font-size: 22px;
}

.supporttable-body div.header {
  font-weight: bold;
  font-size: 24px;
}

@media screen and (max-width: 600px) {
  .supporttable-body {
    width: 100%;
    padding: 20px;
    grid-template-columns: auto auto auto;
  }

  .supporttable-body div {
    font-size: 14px;
  }

  .supporttable-body div.header {
    font-size: 16px;
  }
}
</style>
