<template>
    <Block id="download" :dark="true" :center="true">
      <template slot="subtitle">
        Download
      </template>
      <template slot="title">
        Ready to get some <b>free Superpowers</b><br>
        for your Board?
      </template>
      <template slot="content">
        <div class="appstore-badges">
          <a href="https://apps.apple.com/app/id1559535513#?platform=iphone" target="_blank">
            <img src="/img/appstore-badge-dark.svg"></a>
          <a href="https://play.google.com/store/apps/details?id=im.khad.white_pawn" target="_blank">
            <img src="/img/google-play-badge.png"></a>
          </div>
      </template>
    </Block>
</template>

<script>
import Block from './Block.vue'

export default {
  name: 'DownloadBlock',
  components: {
    Block,
  }
}
</script>

<style scoped>
.appstore-badges {
  margin: 45px 0 0 0;
  text-align: center;
}

.appstore-badges a{
    position: relative;
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 5px;
    height: 54px;
}

.appstore-badges a img{
    height: 100%;
}
</style>
