<template>
  <section :class="{'feature-items-block-dark': 1}">
    <div>
      <span class="subtitle"><slot name="subtitle"></slot></span>
      <h3><slot name="title"></slot></h3>
    </div>
    <div class="items">
      <slot name="items"></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeatureItemsBlockDark',
  props: {}
}
</script>

<style scoped>
.feature-items-block-dark {
  margin: 25px 0 0 0;
  font-size: 26px;
  padding: 25px;
  background: #252732;
  border-radius: 26px;
}

.feature-items-block-dark .items {
  position: relative;
  margin-top: 42px;
  display: flex;
  justify-content: space-evenly;
}

.subtitle {
  display: block;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #9c9da0;
  margin: 0 auto 0 auto;
  text-align: center;
}

h3 {
  display: block;
  max-width: 700px;
  margin: 12px auto 0 auto;
  font-size: 52px;
  text-align: left;
  color: rgb(237, 237, 237);
  text-align: center;
}

h3 b {
  color: rgb(183, 93, 105);
}

@media screen and (max-width: 1150px) {
  .feature-items-block-dark .items {
    flex-wrap: wrap;
    width: 560px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 680px) {
  h3 {
    font-size: 36px;
    margin: 12px 24px;
  }
  .feature-items-block-dark {
    padding: 25px 0 0 0;
  }
  .feature-items-block-dark .items {
    flex-wrap: wrap;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
