<template>
  <div :draggable="enableDragging" @dragstart="$emit('pieceDrag', $event)" class="chesspiece" :style="style"></div>
</template>

<script>
export default {
  name: 'ChessPiece',
  props: {
    piece: String,
    size: Number,
    enableDragging: Boolean
  },
  computed: {
    style() {
      let pieceName = this.piece.toLowerCase();
      let color = this.piece != pieceName ? "w" : "b";
      return {
        width: this.size + "px",
        height: this.size + "px",
        backgroundImage: `url(/img/pieces/${color}/${pieceName}.png)`,
      }
    }
  }
}
</script>

<style scoped>
.chesspiece {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
