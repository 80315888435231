<template>
  <div class="textfield">
    <input v-if="!multiline" class="input" :placeholder="title" :value="value" @input="$emit('input', $event.target.value)" @focus="$emit('posFocusIn')" @blur="$emit('posFocusOut')">
    <textarea v-else class="input" :placeholder="title" :rows="rows ? rows : 6" :value="value" @input="$emit('input', $event.target.value)" @focus="$emit('posFocusIn')" @blur="$emit('posFocusOut')"></textarea>
    <label class="label">{{title}}</label>
  </div>
</template>

<script>
export default {
  name: 'TextField',
  props: {
    value: String,
    title: String,
    multiline: Boolean,
    rows: Number
  }
}
</script>

<style scoped>
.textfield {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
}

.input {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #e7e7e7;
  outline: 0;
  font-size: 16px;
  color: rgb(221, 221, 221);
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.input::placeholder {
  color: transparent;
}

.input:placeholder-shown ~ .label {
  font-size: 16px;
  cursor: text;
  top: 20px;
}

label,
.input:focus ~ .label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 12px;
  color: #9b9b9b;
}

.input:focus ~ .label {
  color: rgb(183, 93, 105);
}

.input:focus {
  padding-bottom: 6px;
  border-bottom: 2px solid rgb(183, 93, 105);
}
</style>
