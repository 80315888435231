import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlay, faCheck, faTimes, faPlusCircle, faPlus, faChessPawn, faTrash, faFileUpload, faFileExport, faMousePointer, faShare, faFile, faArrowDown, faChevronRight, faSearch, faPuzzlePiece, faBolt, faGlobeEurope, faStar, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueCountdown from '@chenfengyuan/vue-countdown';
import VueSmoothScroll from 'vue2-smooth-scroll'
import vmodal from 'vue-js-modal'
import livestreamService from './services/livestreamService'
import routes from './routes'

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name == "Live" && (!from.name || from.name != "Live")) {
    console.log("Connect to livestream-server");
    livestreamService.connect();
  } else if (to.name != "Live" && (from.name && from.name == "Live")) {
    console.log("Disconnect from livestream-server");
    livestreamService.close();
  }
  next();
})

library.add(faPlay)
library.add(faCheck)
library.add(faTimes)
library.add(faInstagram)
library.add(faYoutube)
library.add(faPlusCircle)
library.add(faPlus)
library.add(faChessPawn)
library.add(faTrash)
library.add(faFileUpload)
library.add(faFileExport)
library.add(faMousePointer)
library.add(faShare)
library.add(faFile)
library.add(faArrowDown)
library.add(faChevronRight)
library.add(faSearch)
library.add(faPuzzlePiece)
library.add(faBolt)
library.add(faGlobeEurope)
library.add(faStar)
library.add(faChevronDown)
library.add(faChevronUp)

Vue.use(VueRouter)
Vue.use(VueSmoothScroll)
Vue.use(vmodal)

Vue.component('vue-countdown', VueCountdown);
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
