<template>
  <div :class="{ footer: 1, small: small, dark: dark }">
    <div class="copyright">
      <div class="links">
        <a href="https://khadimfall.com/legal-notice" target="_blank">Legal Notice</a>
        <a href="https://statuspage.freshping.io/62500-WhitePawn" target="_blank">Server Status</a>
      </div>
      <span>
        © 2024 Khadim Fall
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    small: Boolean,
    dark: Boolean,
  },
}
</script>

<style scoped>  
  .links > a {
    margin: 0 10px;
  }

  .footer {
    margin-bottom: 12px;
  }

  .copyright {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: rgb(184, 184, 184);
    padding: 0 120px;
  }

  .footer a {
    color: rgb(183, 93, 105);
    text-decoration: none;
    font-weight: bold;
  }

  .footer.dark {
    background: #252732;
  }

  @media screen and (max-width: 680px) {
    .copyright {
      justify-content: space-evenly;
      padding: 0;
    }
  }
</style>
